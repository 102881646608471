.bg-primary {
  background-color: rgb(24, 118, 143) !important;
  box-shadow: 1px 5px 24px 0 rgba(24, 118, 143, 0.8) !important;
}
.react-tabs .nav-tabs .nav-link.react-tabs__tab--selected,
.react-tabs .nav-tabs .nav-link:hover,
.react-tabs .nav-tabs .nav-link:focus {
  border-bottom: 2px solid #18768f !important;
  color: #18768f !important;
}

.btn-primary {
  background-color: #18768f !important;
  border-color: #18768f !important;
}
.btn-primary:hover {
  background-color: #f48110 !important;
  border-color: #f48110 !important;
}
